<template>
  <section id="feather-icons">
    <h3 class="text-center">
      Custom Icons
    </h3>
    <div
      id="icons-container"
      class="d-flex flex-wrap"
    >
      <b-card
        v-for="icon in customIcons"
        :key="icon.name"
        class="icon-card cursor-pointer text-center mb-2 mx-50"
      >
        <div class="icon-wrapper">
          <feather-icon
            :icon="icon.name"
            size="24"
          />
        </div>
        <b-card-text class="icon-name mb-0 mt-1">
          {{ icon.name }}
        </b-card-text>
      </b-card>
    </div>
    <h3 class="text-center">
      Main Icons
    </h3>
    <div
      id="icons-container"
      class="d-flex flex-wrap"
    >
      <b-card
        v-for="icon in icons"
        :key="icon.name"
        class="icon-card cursor-pointer text-center mb-2 mx-50"
      >
        <div class="icon-wrapper">
          <feather-icon
            :icon="icon.name"
            size="24"
          />
        </div>
        <b-card-text class="icon-name mb-0 mt-1">
          {{ icon.name }}
        </b-card-text>
      </b-card>
    </div>
  </section>
</template>

<script>
import * as icons from 'vue-feather-icons'
import * as customIcons from '@core/components/feather-icon/customIcons'
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  name: 'IconList',
  components: {
    BCard,
    BCardText,
  },
  setup() {
    console.log(customIcons)
    return {
      icons,
      customIcons,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
